import React from 'react';
import { LocationLink } from './location-link';

export const LocationsController = ({ active, locations, isMobile }) => (
  <div className="locations-controller__wrapper">
    <div className={isMobile ? 'filters__pill-list' : 'locations-controller'}>
      {locations.map(({ slug, title, total }) => (
        <LocationLink
          isMobile={isMobile}
          id={slug}
          key={slug}
          // label={`${title} (${total})`}
          label={title === 'All locations' ? 'All' : `${title}`}
          active={active === slug}
        />
      ))}
    </div>
  </div>
);

// Generates URLs for Guide pages.
// If category or subcategory are omitted, the path part is omitted as well.
// All of these are examples of valid outputs of this function:
// /outdoors/connecticut/LIRR
// /outdoors/connecticut
// /outdoors
module.exports = (category, subcategory, transit) => {
  return (
    '/' + ['articles', category, subcategory, transit].filter(Boolean).join('/')
  );
};

import React from 'react';
import { Link } from 'gatsby';

export const LocationLink = ({ id, label, active, isMobile }) => (
  <Link
    to={`/articles/${id !== 'all' ? id : ''}`}
    className={`${!isMobile ? 'location-link' : 'filters__pill-list-item'} ${
      active ? 'active' : ''
    }`}
  >
    <div className="wrapper">
      <span>{label}</span>
      {/* {active && <span className="material-icons">done</span>} */}
    </div>
  </Link>
);

import React from 'react';
import { graphql } from 'gatsby';

import { BrowseAllContainer } from '../containers/browse-all';

const BrowseAll = ({ data, pageContext }) => {
  const { locations, transit, category, subcategory } = pageContext;
  const {
    articles: { nodes },
    ads: { articlesAdUnits },
    bannerAd: { bannerAd },
  } = data;

  return (
    <BrowseAllContainer
      nodes={nodes}
      locations={locations}
      transit={transit}
      category={category}
      subcategory={subcategory}
      ads={articlesAdUnits}
      bannerAd={bannerAd}
    />
  );
};

export const query = graphql`
  query BrowseAll($category: String, $transit: String) {
    articles: allContentfulArticle(
      sort: { fields: publishDate, order: DESC }
      filter: {
        subcategories: { elemMatch: { slug: { nin: ["archive"] } } }
        categories: { elemMatch: { slug: { eq: $category } } }
        places: {
          elemMatch: { routes: { elemMatch: { system: { eq: $transit } } } }
        }
      }
    ) {
      nodes {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
        }
      }
    }

    ads: contentfulHomePage {
      ...ArticlesAdsFragment
    }

    bannerAd: contentfulHomePage {
      ...BannerAdFragment
    }
  }
`;

export default BrowseAll;
